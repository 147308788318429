//
// slide
//
.container-slide {
  @include clearfix();
  max-width:1500px;
  height: 100%;
//  background-color: red;
  margin: 0 auto;
  position: relative;
}
.slick-dotted.slick-slider,
.slide-top {
  margin: 0;
  @media print, screen and(min-width: $screen-sm-min) {
    margin-bottom: 3rem;
  }
}
.slide {
  position: relative;
//  height: 300px;
  height: calc(100vh - 56px);
  overflow: hidden;
  .slide-content {
    .link {
      &:hover {
        img {
          opacity: .7;
        }
      }
    }
  }
  @media print, screen and(min-width: $screen-sm-min) {
    height: 800px;
  }
}
.slide01 {
  background-image: url('../img/slide/slide01.jpg');
  background-size: cover;
  background-position: bottom center;
  .slide-content {
    z-index: 300;
    position: absolute;
    top:10vh;
    left:5vw;
    opacity: 0;
    .catch {
      margin-bottom: 70px;
    }
    .catch_100th {
      margin-bottom: 40px;
    }
    .link {

    }
  }
  .sub-image {
    opacity: 0;
    z-index: 200;
    position: absolute;
    bottom:0;
    right: 0;
    animation-delay:1s;
    @media print, screen and(min-width: $screen-md-min) {
      bottom:auto;
      top:0;
      right: 0;
    }
  }
}.slide02 {
  background-image: url('../img/slide/slide02.jpg');
  background-size: cover;
  background-position: bottom center;
  .slide-content {
    z-index: 300;
    position: absolute;
    top:10vh;
    left:5vw;
    opacity: 0;
    .catch {
      margin-bottom: 70px;
    }
    .link {

    }
  }
  .sub-image {
    opacity: 0;
    z-index: 200;
    position: absolute;
    bottom:0;
    right: 0;
    animation-delay:1s;
    @media print, screen and(min-width: $screen-md-min) {
      bottom:auto;
      top:0;
      right: 0;
    }
  }
}
.slide03 {
  background-image: url('../img/slide/slide03.jpg');
  background-size: cover;
  background-position: bottom center;
  .slide-content {
    z-index: 300;
    position: absolute;
    top:10vh;
    left:5vw;
    opacity: 0;
    .catch {
      margin-bottom: 70px;
    }
    .link {

    }
  }
  .sub-image {
    opacity: 0;
    z-index: 200;
    position: absolute;
    bottom:0;
    right: 0;
    animation-delay:1s;
    @media print, screen and(min-width: $screen-md-min) {
      bottom:auto;
      top:0;
      right: 0;
    }
  }
}
.slide04 {
  background-image: url('../img/slide/slide04.jpg');
  background-size: cover;
  background-position: bottom center;
  .slide-content {
    width: 100%;
    z-index: 300;
    position: absolute;
    top:10vh;
    left:0;
    opacity: 0;
    .catch {
      img {
        margin: 0 auto;
      }
      margin-bottom: 70px;
    }
    .link-saiyou {
      margin: 0 auto;
      &:hover {
        img {
          opacity: .7;
        }
      }
      img {
        margin: 0 auto;
      }
    }
  }
  .sub-image {
    opacity: 0;
    z-index: 200;
    position: absolute;
    bottom:0;
    right: 0;
    animation-delay:1s;
    @media print, screen and(min-width: $screen-md-min) {
      bottom:auto;
      top:0;
      right: 0;
    }
  }
}
.slide05 {
  background-image: url('../img/slide/slide05.jpg');
  background-size: cover;
  background-position: bottom center;
  .slide-content {
    z-index: 300;
    position: absolute;
    top:10vh;
    left:5vw;
    opacity: 0;
    .catch {
      margin-bottom: 70px;
    }
    .link {

    }
  }
  .sub-image {
    opacity: 0;
    z-index: 200;
    position: absolute;
    bottom:0;
    right: 0;
    animation-delay:1s;
    @media print, screen and(min-width: $screen-md-min) {
      bottom:auto;
      top:0;
      right: 0;
    }
  }
}
.slide06 {
  background-image: url('../img/slide/slide06_sp.jpg');
  background-size: cover;
  background-position: top center;
  .slide-content {
    z-index: 300;
    position: absolute;
    top:15vh;
    left:5vw;
    opacity: 0;
    .catch {
      margin-bottom: 70px;
    }
    .link {

    }
  }
  @media print, screen and(min-width: $screen-md-min) {
    background-image: url('../img/slide/slide06.jpg');
  }
  .sub-image {
    opacity: 0;
    z-index: 200;
    position: absolute;
    bottom:0;
    right: 0;
    animation-delay:1s;
    @media print, screen and(min-width: $screen-md-min) {
      bottom:auto;
      top:0;
      right: 0;
    }
  }
}
.slick-dots {
  bottom:25px;
}
.slick-dots li button:before {
  color: $color-white;
  opacity: .7;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: $color-main;
}