.breadcrumb {
  @include fontsize(12);
  display:block;
  list-style: none;
  padding-left: 0;
  margin:0;
  padding:.5em 0;
  li {
    display: inline;
    a {
      color:$color-text;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &:before {
      content: '>';
      margin-right: .5em;
      margin-left: 0.5em;
    }
    &:first-child:before {
      content: '';
      margin-left:0;
    }
  }
}