@charset "UTF-8";

.link-text {
  @include fontsize(16);
    display: inline-block;
    color:$color-text;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &:before {
      display: inline-block;
      content: '';
      margin-right: 5px;
      margin-top: -5px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 0 5px 5px;
      border-color: transparent transparent transparent $color-main;
      transition: border-color .2s linear;
  }
}

.button {
  display: inline-block;
  background-color: $color-main;
  border: solid 1px $color-main;
  border-radius: 1.5em;
  color: $color-white;
  cursor: pointer;
  text-decoration: none;
  line-height: 1.2;
  padding: .5em 2em .5em 2em;
  vertical-align: middle;
  box-shadow: 0 3px 3px -3px rgba($color-black,.8);
  transition: background-color .2s linear,color .2s linear,;
  position: relative;
  &:after {
    display: inline-block;
    content: '';
    position: absolute;
    top:50%;
    right: .75em;
    margin-top: -5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent $color-white;
    transition: border-color .2s linear;
  }
  &:hover {
    background-color: transparent;
    background-color: rgba($color-main,.1);
    color: $color-main;
    &:after {
      border-color: transparent transparent transparent $color-main;
    }
  }
  @each $name,$color in $site-palette {
    &.#{$name} {
      background-color: $color;
      border: solid 1px $color;
      &:hover {
        background-color: transparent;
        background-color: rgba($color,.1);
        color: $color;
      }
    }
  }
}

.button-small {
  @include fontsize(12);
  position: relative;
  display: inline-block;
  background-color: $color-main;
  border: solid 1px $color-main;
  border-radius: 1.5em;
  color: $color-white;
  text-decoration: none;
  line-height: 1.2;
  padding: .15em 2.5em .15em 2em;
  vertical-align: middle;
  box-shadow: 0 3px 3px -3px rgba($color-black,.8);
  transition: background-color .2s linear,color .2s linear,;
  &:hover {
    background-color: transparent;
    color: $color-main;
    &:after {
      background-image: url('../img/common/icon-arrow-black.svg');  
      opacity: .7;
    }
  }
  &:after {
    position: absolute;
    top:50%;
    margin-top: -4px;
    right: 9px;
    display: inline-block;
    content: '';
    background-image: url('../img/common/icon-arrow-white.svg');
    width: 9px;
    height: 9px;
  }
  @each $name,$color in $site-palette {
    &.#{$name} {
      background-color: $color;
      border: solid 1px $color;
      &:hover {
        background-color: transparent;
        background-color: rgba($color,.1);
        color: $color;
      }
    }
  }
}

.button-square {
  font-family: $font-family-sans-serif2;
  display: inline-block;
  background-color: $color-gray-dark;
  color:$color-white;
  line-height: 1;
  letter-spacing: .05em;
  text-decoration: none;
  padding: 10px 3em;
  position: relative;
  &:after {
    position: absolute;
    top:50%;
    margin-top: -4px;
    right: 9px;
    display: inline-block;
    content: '';
    background-image: url('../img/common/icon-arrow-white.svg');
    width: 9px;
    height: 9px;
  }
  &:hover {
    opacity: .8;
  }
}

.button-white {
  display: inline-block;
  border: solid 1px $color-text;
  color:$color-text;
  line-height: 1;
  letter-spacing: .05em;
  text-decoration: none;
  padding: 10px 3em;
  position: relative;
  &:after {
    position: absolute;
    top:50%;
    margin-top: -4px;
    right: 9px;
    display: inline-block;
    content: '';
    background-image: url('../img/common/icon-arrow-black.svg');
    width: 9px;
    height: 9px;
  }
  &.link-inpage:after {
    transform: rotate(90deg);
  }
  &:hover {
    background-color: rgba($color-text,.05);
  }
}
.button-blank {
  display: inline-block;
  border: solid 1px $color-text;
  color:$color-text;
  line-height: 1;
  letter-spacing: .05em;
  text-decoration: none;
  padding: 10px 2em;
  position: relative;
  &:after {
    position: absolute;
    top:50%;
    margin-top: -4px;
    left: 9px;
    display: block;
    content: '';
    background-image: url('../img/common/icon-arrow-black.svg');
    width: 9px;
    height: 9px;
  }
  &.link-inpage:after {
    transform: rotate(90deg);
  }
  &:hover {
    background-color: rgba($color-text,.05);
  }
}