@charset "UTF-8";
@media print {
  body {
    width: 1250px;
    transform: scale(0.8);
    transform-origin: 0 0;
  }
}
html {
  box-sizing: border-box;
  font-size: $font-size-base;
  overflow-y: scroll;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
}

*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: inherit;
}

body {
  font-family: $font-family-base;
  line-height: $line-height-base;
  color:$color-text;
}

:link {
  color:$color-link;
}
.container {
  @include clearfix();
  position: relative;
  max-width: $container-lg;
  margin-left: auto;
  margin-right: auto;
  padding: 0 $gutter / 2;
}
.container-middle {
  @include clearfix();
  position: relative;
  max-width: ($container-lg - 100px);
  margin-left: auto;
  margin-right: auto;
  padding: 0 $gutter / 2;
}
.container-medium {
  @include clearfix();
  position: relative;
  max-width: $container-md;
  margin-left: auto;
  margin-right: auto;
  padding: 0 $gutter / 2;
}
.container-small {
  @include clearfix();
  position: relative;
  max-width: $container-sm;
  margin-left: auto;
  margin-right: auto;
  padding: 0 $gutter / 2;
}
.container-wide {
  @include clearfix();
  position: relative;
  max-width: $container-wide;
  margin-left: auto;
  margin-right: auto;
  padding: 0 $gutter / 2;
}
.container-front {
  @include clearfix();
  position: relative;
  max-width: $container-wide;
  margin-left: auto;
  margin-right: auto;
  @media print, screen and (min-width: $screen-md-min) {
    padding: 0 $gutter / 2;
  }
}
.container-full {
  @include clearfix();
  position: relative;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 $gutter / 2;
}
.container-full-nopad {
  @include clearfix();
  position: relative;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
//
// header
//
.site-header {
  background-color: rgba($color-white,.8);
  position: fixed;
  z-index: 100;
  width: 100%;
  padding: .25rem 0;
  @media print, screen and (min-width: $screen-sm-min) {
    position: relative;
  }
  .site-logo {
    width:300px;
    text-align: left;
    // a:last-child {
    //   margin-left: 5px;
    //   img {
    //     vertical-align: bottom;
    //   }
    // }
  }
}
.site-logo {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 160px;
  a {
    display: inline-block;
    height: auto;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}
.site-main {
  display: block;
  padding-top: 56px;
//  padding-bottom: 5rem;
  @media print, screen and(min-width: $screen-sm-min) {
    padding-top: 0;
  }
}
.box-main {
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding-bottom: 3rem;
  img {
    max-width:100%;
    height:auto;
  }
  @media print, screen and(min-width: $screen-md-min) {
    flex: 1;
    margin-right: 50px;
  }
}
.box-main-right {
  @media print, screen and(min-width: $screen-md-min) {
    order: 2;
    margin-right: 0;
    margin-left: 50px;
  }
}
.box-side {
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  img {
    max-width:100%;
    height:auto;
  }
  @media print, screen and(min-width: $screen-md-min) {
    width: 320px;
    padding-left: 20px;
//    border-left: solid 1px $color-gray;
  }
}
.box-side-left {
  @media print, screen and(min-width: $screen-md-min) {
    padding-left: 0;
    padding-right: 20px;
    order: 1;
  }
}
.circle {
  display: inline-block;
  width: .75em;
  height: .75em;
  border-radius: 50%;
  background-color: $color-main;
  @each $name,$color in $site-palette {
    &.#{$name} {
      background-color: $color;
    }
  }
}

//
// footer
//
.site-footer {
  background-color: $color-gray;
  padding: 1rem 0 1rem;
  .site-logo {
    text-align: left;
  }
  .copyright {
    margin: 0;
    padding-top: .5rem;
    @include fontsize(10);
    text-align: center;
  }
}
.box-sitemap {
  display: none;
  border-bottom: solid 1px rgba($color-gray_dark,.2);
  margin-bottom: 1rem;
  padding: 1rem 0 2rem 0;
  .site-logo {
    margin-bottom: 1rem;
  }
  @media print, screen and(min-width: $screen-sm-min) {
    display: block;
  }
  @media print, screen and(min-width: $screen-md-min) {
    display: flex;
    justify-content: flex-start;
    .site-logo {
      margin-right: 3rem;
    }
  }
}
// ページトップに戻るボタン
.btn-pagetop {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  cursor: pointer;
  display: none;
  height: 50px;
  line-height: 1;
  opacity: 0;
  text-align: center;
  transform: perspective(0);
  transition: transform .3s ease-in-out,background-color .3s ease-in-out;
  width: 50px;
  background-color:rgba($color-main,.8);
  z-index: 100;
  background-image: url('../img/common/icon-pageup_white.svg');
  background-repeat: no-repeat;
  background-position: center center;

  &.visible {
    animation-duration: .5s;
    animation-name: fade-in;
    display: block;
    opacity: 1;
  }

  &:hover {
    transform: scale(1.2);
  }
}

@keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
    transform: scale(1);
  }

  1% {
    display: block;
    opacity: 0;
  }
  
  50% {
    transform: scale(1.2);
  }

  100% {
    display: block;
    opacity: 1;
    transform: scale(1);
  }
}

.br-sp_none {
  display: none;
  @media print, screen and(min-width: $screen-sm-min) {
    display: inline;
  }
}
.br-sp_show {
  @media print, screen and(min-width: $screen-sm-min) {
    display: none;
  }
}

.mb-basic {
  margin-bottom: 2rem !important;
  @media print, screen and(min-width: $screen-sm-min) {
    margin-bottom: 5rem !important;
  }
}

.contact-addr {
  @include fontsize(18);
  @media print, screen and(min-width: $screen-sm-min) {
    @include fontsize(22);
    text-align: center;
  }
}

.contact-tel {
  text-align: center;
  @include fontsize(28);
  font-weight: bold;
  .tel {
    @include fontsize(32);
  }
  @media print, screen and(min-width: $screen-sm-min) {
    @include fontsize(30);
    .tel {
      @include fontsize(36);
    }
  }
}
