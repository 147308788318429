//
// core
//

.title-core {
  @include fontsize(20);
  font-weight: bold;
  margin: 0 0 1em 0;
  @media print, screen and(min-width: $screen-sm-min) {
    @include fontsize(26);
  }
}
.card-core {
  @include clearfix();
  margin-bottom: 2rem;
  .card-photo {
    float: right;
    max-width: 50%;
    padding-left: 2rem;
  }
}
.catch-core {
  @include fontsize(20);
  color:$color-main;
  font-weight: bold;
  line-height: 1.5;
  margin: 0 0 1em 0;
  @media print, screen and(min-width: $screen-sm-min) {
    @include fontsize(28);
  }
}
.lead-core {
  line-height: 2.2;
  padding-right: 1em;
}
.catch-tech {
  @include fontsize(20);
  line-height: 1.5;
  margin: 0 0 1em 0;
  @media print, screen and(min-width: $screen-sm-min) {
    @include fontsize(28);
  }
}
#map {
  height: 400px;
}

//
// core
//
.core-point01,
.core-point02,
.core-point03 {
  overflow: hidden;
  margin-bottom: 50px;
}
.core-point02 .point-info .title{
  background-color: rgba(#00538d,.82);
  &:before {
    background-color: rgba(#00538d,.82);
  }
}
.core-point03 .point-info .title{
  background-color: rgba(#008080,.82);
  &:before {
    background-color: rgba(#008080,.82);
  }
}

.point-info {
  position: relative;
  .title {
    @include fontsize(25);
    font-family: $font-family-abel;
    background-color: rgba(#2d2b7d,.82);
    color:$color-white;
    line-height: 1;
    padding: 10px;    
  }
  .catch {
    @include fontsize(20);
    font-weight: bold;
    line-height: 1.4;
    margin-bottom: 1em;
  }
  @media print, screen and(min-width: $screen-sm-min) {
    .photo {
      position: absolute;
      top:0;
      left: 500px;
      width: auto;
      max-width: none;
    }
    .title {
      @include fontsize(45);
      position: absolute;
      z-index: 10;
      line-height: 1;
      top:20px;
      left: 0;
      min-width: 260px;
      padding: 20px 20px 20px 0;
      &:before {
        content: '';
        display: block;
        position: absolute;
        background-color: rgba(#2d2b7d,.82);
        height: 100%;
        width: 500px;
        top:0;
        left: -500px;
      }
    }
    .catch {
      @include fontsize(28);
    }
    .text {
      padding: 150px 0 0 0;
    }
  }
}

.box-menu-point {
  position: absolute;
  bottom:0;
  left: 0;
  width:100%;
}

//
// technical
//
.box-feature {
//  margin-top: -5em;
  @include fontsize(14);
//  background-color: $color-white;
  text-align: center;
  padding: 1em;
  font-weight: bold;
  @media print, screen and(min-width: $screen-sm-min) {
    @include fontsize(20);
    padding: 1em 0;
  }
}
.sec-technical01 {
  background-image: url('../img/strong/technical-photo01.jpg');
  background-size: cover;
  background-position: top center;
  margin-bottom: 50px;
  padding: 2rem 0;
  @media print, screen and(min-width: $screen-sm-min) {
    padding: 150px 0;
    margin-bottom: 100px;
  }
}
.sec-solution01 {
  background-image: url('../img/strong/solution-photo01.jpg');
  background-size: cover;
  background-position: top center;
  margin-bottom: 50px;
  padding: 2rem 0;
  @media print, screen and(min-width: $screen-sm-min) {
    padding: 150px 0;
    margin-bottom: 100px;
  }
}
.sec-knowledge01 {
  background-image: url('../img/strong/knowledge-photo01.jpg');
  background-size: cover;
  background-position: center center;
  margin-bottom: 50px;
  padding: 2rem 0;
  @media print, screen and(min-width: $screen-sm-min) {
    padding: 100px 0;
  }
}
.catch-future {
  @include fontsize(20);
  line-height: 1.5;
  color:$color-main;
  font-weight: bold;
  @media print, screen and(min-width: $screen-sm-min) {
    @include fontsize(30);
    text-align: center;
  }
}
.lead-future {
  @media print, screen and(min-width: $screen-sm-min) {
    @include fontsize(17);
    text-align: center;
  }
}
.fig-shikaku {
  text-align: center;
  color:$color-main;
  line-height: 1.1;
  margin: 30px 0;
  .title {
    @include fontsize(18);
    letter-spacing: .1em;
  }
  .count {
    @include fontsize(50);
    .small {
      @include fontsize(24);
    }
  }
  .date {
    @include fontsize(12);
    letter-spacing: .1em;
  }
  @media print, screen and(min-width: $screen-sm-min) {
    .title {
      @include fontsize(22);
    }
    .count {
      @include fontsize(82);
      .small {
        @include fontsize(34);
      }
    }
    .date {
      @include fontsize(12);
    }
  }
}

.list-shikaku {
  list-style-type: none;
  margin: 0;
  padding: 0;
  .item-shikaku {
    flex: 0 0 46%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    border-bottom: solid 1px $color-gray;
  }
  @media print, screen and(min-width: $screen-sm-min) {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    .item-shikaku {
      border-bottom: 0;
    }
  }
}

//
// biru
// 
.catch-biru {
  @include fontsize(18);
  line-height: 1.5;
  color:$color-main;
  font-weight: bold;
  @media print, screen and(min-width: $screen-sm-min) {
    @include fontsize(28);
    text-align: center;
  }
}
.catch-biru_left {
  @include fontsize(18);
  line-height: 1.2;
  color:$color-main;
  font-weight: bold;
  margin-bottom: 1em;
  @media print, screen and(min-width: $screen-sm-min) {
    @include fontsize(28);
  }
}
.title-biru {
  @include fontsize(18);
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: .5em;
  @media print, screen and(min-width: $screen-sm-min) {
    @include fontsize(20);
  }
}
.text-biru {
  margin-bottom: 2em;
}
.list-biru {
  list-style-type: none;
  margin: 0 0 2em 0;
  padding: 0;
  .list-item {
    position: relative;
    padding-left: 1.25em;
    .head {
      position: absolute;
      top:0;
      left:0;
    }
  }
}
.list-biru_smart {
  margin: 0 0 1em;
  padding: 0 0 0 1.5em;
}