//
// SIERRA MIXINS
//

// Clearfix
// Source: http://nicolasgallagher.com/micro-clearfix-hack/
@mixin clearfix() {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin fontsize($size: 14, $base: $font-base) {
  font-size: ($size / $base) * 1rem;
}
