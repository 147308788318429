@charset "UTF-8";

.label {
  @include fontsize(12);
  display: inline-block;
  min-width: 6em;
  text-align: center;
  padding: .3em 2em;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  
  background-color: $color-main;
  color: $color-white;
  vertical-align: middle;
  
  @each $name,$color in $site-palette {
    &.#{$name} {
      background-color: $color;
    }
  }
}
