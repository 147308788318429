//
// vison
//

.title-vision {
  @include fontsize(20);
  text-align: center;
  font-weight: bold;
}
.catch-vision {
  text-align: center;
  margin: 3rem 1rem;
}
.lead-vision {
  @media print, screen and(min-width: $screen-sm-min) {
    line-height: 2.8;
    text-align: center;
  }
}


.vision-title {
  @include fontsize(22);
  border-bottom: solid 1px $color-gray;
  text-align: center;
  padding-bottom: .5em;
  margin-bottom: .5em;
}
.vision-title_catch {
  @include fontsize(19);
  margin-bottom: 3rem;
  text-align: center;
}
.vision-point {
  margin-bottom: 2rem;
  .title-eng {
    @include fontsize(16);
    font-weight: bold;
    color:$color-main;
  }
  .catch-point {
    @include fontsize(20);
    line-height: 1.4;
    font-weight: bold;
    margin: .25em 0;
  }
  .text-point {
    
  }
  @media print, screen and(min-width: $screen-sm-min) {
    text-align: center;
    .title-eng {
      @include fontsize(21);
    }
    .catch-point {
      @include fontsize(26);
    } 
  }
}
.sec-brand {
  background-image: url('../img/vision/bg-brand.jpg');
  background-position: bottom center;
  background-size: cover;
  margin-bottom: 0;
  padding-top: 20px;
  @media print, screen and(min-width: $screen-md-min) {
    padding-top: 50px;
    margin-bottom: 100px;
    background-attachment: fixed;  
  }
}
.brand_pc {
  display: none;
  position: relative;
  text-align: center;
  img {
    vertical-align: bottom;
  }
  .brand_link {
    position: absolute;
    width: 100%;
    bottom:1.5rem;
    left: 0;
    text-align: center;
    a {
      @include fontsize(18);
      color:$color-text;
      &:hover {
        opacity: .6;
      }
    }
  }
  @media print, screen and(min-width: $screen-md-min) {
    display: block;
  }
}
.brand_sp {
  position: relative;
  text-align: center;
  img {
    vertical-align: bottom;
  }
  .brand_link {
    position: absolute;
    width: 100%;
    bottom:5px;
    left: 0;
    text-align: center;
    a {
      color:$color-text;
      &:hover {
        opacity: .6;
      }
    }
  }
  @media print, screen and(min-width: $screen-md-min) {
    display: none;
  }
}

.box-video {
  position: relative;
  text-align: center;
  .catch-video {
    position: absolute;
    width:250px;
    height: auto;
    top:50%;
    left: 50%;
    margin-top:-31px;
    margin-left: -125px;
  }
  video {
    max-width: 100%;
  }
  @media print, screen and(min-width: $screen-sm-min) {
    .catch-video {
      width: auto;
      position: absolute;
      top:50%;
      left: 50%;
      margin-top:-51px;
      margin-left: -207px;
    } 
  }
}

.box-vision {
  margin-bottom: 3rem;
  .box-info {
    .no-box {
      @include fontsize(20);
      color: rgba($color-text,.5);
      margin: .5em 0;
    }
    .title-box {
      @include fontsize(24);
      margin: 0;
      line-height: 1.5;
    }
  }
  .box-photo {
    
  }
  @media print, screen and(min-width: $screen-sm-min) {
    display: flex;
    .box-info {
      flex: 0 0 50%;
      .no-box {
        @include fontsize(20);
        color: rgba($color-text,.5);
        margin: .5em 0;
      }
      .title-box {
        @include fontsize(24);
        margin: 0;
        line-height: 1.5;
      }
    }
    .box-photo {
      flex: 0 0 50%;
    } 
  }
}

//
// message
//

.bg-aisatsu {
  // background: linear-gradient(0deg, #F7F8F8,#FFFFFF );
  img {
    vertical-align: bottom;
  }
}
.mb-message {
  margin-bottom: 50px;
  @media print, screen and(min-width: $screen-sm-min) {
    margin-bottom: 100px;
  }
}
.box-message-photo {
  
  max-width: 790px;
  margin: 0 auto 50px auto;
  .message-photo { 
    text-align: center;
    padding: 0 15px;
    margin-bottom: 50px;
    img {
      max-width: 80%;
      vertical-align: bottom;
    }
  }
  @media print, screen and(min-width: $screen-sm-min) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    .message-photo { 
      margin-bottom: 0;
      img {
        max-width: 100%;
      }
    }
  }

}

.text-message {
  @media print, screen and(min-width: $screen-sm-min) {
    padding-top:50px;
  }
  @media print, screen and(min-width: $screen-md-min) {
    padding-top:100px;
  }
}

.message-syomei {
  @include fontsize(20);
  font-weight: bold;
  text-align: right;
  font-family: $font-family-serif;
}
.list-houshin {
  @include fontsize(16);
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-weight: bold;
  @media print, screen and(min-width: $screen-sm-min) {
    @include fontsize(18);
    text-align: center;
    .list-item {
      display: inline-block;
      padding: 1em 1em;
    }
  }
}
.list-houshin_long {
  @include fontsize(16);
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-weight: bold;
  .list-item {
    padding: .5em 0;
    border-bottom: dotted 1px $color-gray;
    &:last-child {
      border-bottom: 0;
    }
  }
  @media print, screen and(min-width: $screen-sm-min) {
    @include fontsize(18);
  }
}
.list-chotatsu {
  @include fontsize(16);
  list-style-type: none;
  margin: 0;
  padding: 0;
  .list-item {
    padding: .5em 0;
    border-bottom: dotted 1px $color-gray;
    &:last-child {
      border-bottom: 0;
    }
  }
  @media print, screen and(min-width: $screen-sm-min) {
    @include fontsize(18);
  }
}

//
// chotatsu
//
.bg-place_chotatsu {
//  background-color: rgba($color-gray,.3);
//  padding: 3rem 0;
}
.card-place_chotatsu {
  background-color: $color-white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .info {
    padding: 1rem 1rem 0 1rem;
    .title {
      @include fontsize(18);
      font-weight: bold;
    }
    .tantou {
      @include fontsize(14);
      font-weight: bold;
    }
    .text {
      @include fontsize(14);
    }
  }
  .tel {
    padding: 0 1rem;
    margin-bottom: 1rem;
  }
  .map {
    position: relative;
    padding-bottom: 56.25%;
    
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  @media print, screen and(min-width: $screen-sm-min) {
    .info {
      padding: 1rem 2rem 0 2rem;
      .title {
        @include fontsize(20);
        font-weight: bold;
      }
      .text {
        @include fontsize(16);
      }
    }
    .tel {
      padding: 0 2rem;
    }
    .map {
      padding-bottom: 280px;
    }
  }
}

//
// place
//
.bg-place {
  padding: 3rem 0;
  &:nth-child(odd){
    background-color: #f7f7f7;
  }
}

.pos {
  padding-top:70px;
  margin-top: -70px;
}
.card-place {
//  background-color: $color-white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.card_small {
    border-bottom: solid 1px #ccc;
    padding-bottom: 2rem;
  }
//  .button-blank {
//    background-color: $color-white;
//    &:hover {
//      background-color: rgba($color-white,.6);
//    }
//  }
  .info {
    padding: 1rem 0 0 0;
    .title {
      @include fontsize(18);
      font-weight: bold;
    }
    .tantou {
      @include fontsize(14);
      font-weight: bold;
    }
    .text {
      @include fontsize(14);
    }
  }
  .tel {
    margin-bottom: 1rem;
  }
  .map {
    position: relative;
    padding-bottom: 56.25%;
    border: solid 1px $color-gray;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  @media print, screen and(min-width: $screen-sm-min) {
    .info {
      padding: 1rem 0 0 0;
      .title {
        @include fontsize(18);
        font-weight: bold;
      }
      .text {
        @include fontsize(16);
      }
    }
    .map {
      padding-bottom: 280px;
    }
  }
}

//
// chotatsu
//
.lead-chotatsu {
  @media print, screen and(min-width: $screen-sm-min) {
    text-align: center;
  }
}

//
// csr
//
.ol-csr {
  list-style-type: none;
  margin: 0;
  padding: 0;
  .no {
    position: absolute;
    top:0;
    left: 0;
    font-weight: bold;
  }
  .title {
    font-weight: bold;
  }
  > li {
    position: relative;
    border-bottom: dotted 1px $color-gray;
    padding-bottom: .5em;
    padding-left: 2em;
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: .5em;
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      &:before {
        position: absolute;
        top:0;
        left: 0;
        content: '・';
      }
      position: relative;
      margin: .25em　0;
      padding-left: 1em;
    }
  }
}
.kouhou-title {
  @include fontsize(16);
}

.message-name {
  font-weight: bold;
  @include fontsize(18);
  display: inline-block;
  vertical-align: text-bottom;
}
