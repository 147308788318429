.bg-before100th {
  background-image: url('../img/before100th/bg-100th.jpg');
  background-size: cover;
  background-position: bottom center;
  padding: 50px 0;
}
.link-text_100th {
    @include fontsize(16);
    display: inline-block;
    color:$color-text;
    text-decoration: underline;
    &:hover {
      opacity: .7;
    }
}