@charset "UTF-8";
/* ---------------------------------------- *
 * SIERRA form
 * 20150825 sakakibara-t
 * ---------------------------------------- */

/* ---------------------------------------- *
 Normalize non-controls
 Restyle and baseline non-control form elements.
 * ---------------------------------------- */

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  // Chrome and Firefox set a `min-width: min-content;` on fieldsets,
  // so we reset that to ensure it behaves more like a standard block element.
  // See https://github.com/twbs/bootstrap/issues/12359.
  min-width: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  line-height: inherit;
  border: 0;
}

label {
  display: inline-block;
  max-width: 100%; // Force IE8 to wrap long content (see https://github.com/twbs/bootstrap/issues/13141)
  margin-bottom: 5px;
  //font-weight: bold;
}

/* ---------------------------------------- *
 Normalize form controls
 While most of our form styles require extra classes, some basic normalization
 is required to ensure optimum display with or without those classes to better
 address browser inconsistencies.
* ---------------------------------------- */

// Position radios and checkboxes better
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9; // IE8-9
  line-height: normal;
}

// Set the height of file controls to match text inputs
input[type="file"] {
  display: block;
}

// Make range inputs behave like textual form controls
input[type="range"] {
  display: block;
  width: 100%;
}

// Make multiple select elements height not fixed
select[multiple],
select[size] {
  height: auto;
}
select {
  padding: .5em 0;
  line-height: 1.2;
}


/* ---------------------------------------- *
// Common form controls
//
// Shared size and type resets for form controls. Apply `.form-control` to any
// of the following form controls:
//
// select
// textarea
// input[type="text"]
// input[type="password"]
// input[type="datetime"]
// input[type="datetime-local"]
// input[type="date"]
// input[type="month"]
// input[type="time"]
// input[type="week"]
// input[type="number"]
// input[type="email"]
// input[type="url"]
// input[type="search"]
// input[type="tel"]
// input[type="color"]
* ---------------------------------------- */
.form-control {
  margin: .5rem 0;
  display: block;
  width: 100%;
  max-width: 100%;
  &.inline {
    width: auto;
    display: inline-block;
  }
  background-image: none; // Reset unusual Firefox-on-Android default style; see border: 1px solid $input-border;
  line-height: $line-height-base;
  // Disabled and read-only inputs
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
  }
  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
  }
}
.form-control_inline {
  margin: .5rem 0;
  display: inline-block;
  max-width: 100%;
  &.inline {
    width: auto;
    display: inline-block;
  }
  background-image: none; // Reset unusual Firefox-on-Android default style; see border: 1px solid $input-border;
  line-height: $line-height-base;
  // Disabled and read-only inputs
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
  }
  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
  }
}
/* ---------------------------------------- *
 Reset height for `textarea`s
* ---------------------------------------- */
textarea.form-control {
  height: auto;
}


/* ---------------------------------------- *
 Form groups
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.
* ---------------------------------------- */
.form-group {

}


/* ---------------------------------------- *
 Checkboxes and radios
* ---------------------------------------- */

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;

  label {
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
  }
}
.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9;
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px; // Move up sibling radios or checkboxes for tighter spacing
}

// Radios and checkboxes on same line
.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px; // space out consecutive inline controls
}

// Apply same disabled cursor tweak as for inputs
// Some special care is needed because <label>s don't inherit their parent's `cursor`.
//
// Note: Neither radios nor checkboxes can be readonly.
input[type="radio"],
input[type="checkbox"] {
  &[disabled],
  &.disabled,
  fieldset[disabled] & {
    cursor: not-allowed;
  }
}
// These classes are used directly on <label>s
.radio-inline,
.checkbox-inline {
  &.disabled,
  fieldset[disabled] & {
    cursor: not-allowed;
  }
}
// These classes are used on elements with <label> descendants
.radio,
.checkbox {
  &.disabled,
  fieldset[disabled] & {
    label {
      cursor: not-allowed;
    }
  }
}


/* form-control-static */
.form-control-static {
  // Size it appropriately next to real form controls
  
  padding-left:10px;
  background-color: rgba($color-gray,.2);
  border:solid 1px $color-gray;
  // Remove default margin from `p`
  margin: .5rem 0;
  
  &.input-lg,
  &.input-sm {
    padding-left: 0;
    padding-right: 0;
  }
}


// Horizontal forms
//
// Horizontal forms are built on grid classes and allow you to create forms with
// labels on the left and inputs on the right.

.form-horizontal {

  // Consistent vertical alignment of radios and checkboxes
  //
  // Labels also get some reset styles, but that is scoped to a media query below.
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline {
    margin-top: 0;
    margin-bottom: 0;
  }
  // Account for padding we're adding to ensure the alignment and of help text
  // and other content below items
  .radio,
  .checkbox {
    
  }

  // Make form groups behave like rows
  .form-group {
    //@include make-row;
  }

}

.control-label {
  margin: .5rem 0 0 0;
  @media (min-width: $screen-sm-min) {
    margin: .5rem 0;
    text-align: right;
  }
}

.form-cautoin {
  background: rgba($color-red,.1);
  color:$color-red;
  margin: 0;
  padding: 0 .5rem;
}
.must {
  color:$color-red;
}

.button[disabled] {
  background-color: $color-gray;
  color:$color-white;
  border-color: $color-gray;
  cursor: not-allowed;
  &:hover {
    background-color: $color-gray;
    color:$color-white;
  }
}

#address1 {
  font-family: $font-family-sans-serif2;
}