@charset "utf-8";
/* ---------------------------------------- *
 * SIERRA GRID
 * 20150721 sakakibara-t
 * ---------------------------------------- */
$colum-num:12 !default;
$col-gutter:30px !default;
/* カラムの定義 */
.grid-container {
  display: flex;
  flex-wrap:wrap;
}
.grid-padding-x {
  margin-left:  ($gutter / -2);
  margin-right: ($gutter / -2);
  .col-five {
    padding-left: $gutter / 2;
    padding-right: $gutter / 2;
  }
  @for $i from 1 through $colum-num {
    .col-#{$i},
    .col-lg-#{$i},
    .col-md-#{$i},
    .col-sm-#{$i},
    .col-xs-#{$i} {
      padding-left: $gutter / 2;
      padding-right: $gutter / 2;
    }
  }
}
.grid-margin-y {
  .col-five {
      margin-bottom: $gutter;
  }
  @for $i from 1 through $colum-num {
    .col-#{$i},
    .col-lg-#{$i},
    .col-md-#{$i},
    .col-sm-#{$i},
    .col-xs-#{$i} {
      margin-bottom: $gutter;
    }
  }
}
.grid-margin-0 {
  @for $i from 1 through $colum-num {
    .col-#{$i},
    .col-lg-#{$i},
    .col-md-#{$i},
    .col-sm-#{$i},
    .col-xs-#{$i} {
      margin-bottom: 0;
    }
  }
}
@mixin make-col() {
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  img {
    max-width:100%;
    height:auto;
  }
}
/* カラム幅取得 */
@mixin col-width($num) {
  width: 100% / $colum-num * $num;
}
.col-five {
  @include make-col();
}
/* .col[0-9] $colum-num を最大数 */
@for $i from 1 through $colum-num {
  .col-#{$i} {
    @include make-col();
    width: 100% / $colum-num * $i;
  }
  .col-lg-#{$i} {
    @include make-col();
  }
  .col-md-#{$i} {
    @include make-col();
  }
  .col-sm-#{$i} {
    @include make-col();
  }
  .col-xs-#{$i} {
    @include make-col();
    width: 100% / $colum-num * $i;
  }
}
.row {
    @include clearfix();
}
/* sm-size */
@media print, screen and(min-width: $screen-sm-min) {
  .row {
    @include clearfix();
    margin-left:  ($gutter / -2);
    margin-right: ($gutter / -2);
  }
  @for $i from 1 through $colum-num {
    .col-sm-#{$i} {
      width: 100% / $colum-num * $i;
    }
  }
}
/* md-size */
@media print, screen and(min-width: $screen-md-min) {
  @for $i from 1 through $colum-num {
    .col-md-#{$i} {
      width: 100% / $colum-num * $i;
    }
  }
  .col-five  {
    width:20%;
  }
}
/* lg-size */
@media print, screen and(min-width: $screen-lg-min) {
  @for $i from 1 through $colum-num {
    .col-lg-#{$i} {
      width: 100% / $colum-num * $i;
    }
  }
}