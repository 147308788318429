.page-h3 {
  @include fontsize(20);
  color:$color-main;
  text-align: center;
  font-weight: bold;
  margin: 1em 0;
  .small {
    margin-top:.5em;
    display: block;
    font-weight: normal;
  }
  &.color-black {
    color:$color-text;
  }
  @media print, screen and(min-width: $screen-sm-min) {
    @include fontsize(28);
  }
}
.page-h4 {
  @include fontsize(20);
  text-align: center;
  font-weight: bold;
  margin: 1em 0;
  @media print, screen and(min-width: $screen-sm-min) {
    @include fontsize(24);
  }
}
.box-page {
  border: solid 5px $color-gray;
  padding: 1rem;
  margin-bottom: 2rem;
  .box-title {
    @include fontsize(18);
    text-align: center;
    margin: .5rem 0 1rem;
  }
}

.contact-lead {
  @media print, screen and(min-width: $screen-sm-min) {
    text-align: center;
  }
}

.contact-ul {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
}

