// frontpage-list
.news-list {
  list-style-type: none;
  margin: 0 0 2rem;
  padding: 0;
  .list-item {
    border-bottom: solid 1px lighten($color-gray,12%);
    position: relative;
    margin-bottom: .5rem;
    padding: 0 0 .5rem 0;
    border-bottom: solid 1px $color-gray;
    .post-date {
      @include fontsize(12);
      margin-right: .5em;
    }
    .label {
      @include fontsize(10);
      margin-right: .5em;
    }
    .post-title {
      @include fontsize(14);
      display: block;
      line-height: 1.4;
      a {
        color:$color-text;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  @media print, screen and(min-width: $screen-sm-min) {
    .list-item {
      padding: 0 0 .75rem 0;
      margin-bottom: .75rem;
      &:last-child {
        margin-bottom: 0;
      }
      .post-date {
        @include fontsize(14);
      }
      .label {
        @include fontsize(12);
      }
      .post-title {
        @include fontsize(14);
        margin-top: .5rem;
        display: block;
      }
    } 
  }
  
}

// news
.single-h3 {
  color:$color-main;
  margin: 0 0 1em 0;
  padding-bottom: 1em;
  border-bottom: solid 1px rgba($color-text-light,.2);
  line-height: 1.4;
  @include fontsize(20); 
  @media print, screen and(min-width: $screen-sm-min) {
    @include fontsize(28); 
  }
}
.single-date {
  color:$color-gray_dark;
  margin-bottom: .5em;
}
.single-category {
  @include clearfix();
  margin-bottom: 2rem;
  @media print, screen and(min-width: $screen-sm-min) {
    margin-bottom: 3rem;
  }
}
.span-category {
  display: inline-block;
  vertical-align: middle;
  .post-categories {
    vertical-align: text-bottom;
    display: inline-block;
  }
}

.box-sns {
  margin: 5rem 0;
  @include clearfix();
  .btn-twitter,
  .btn-fb {
    float:left;
    height: 20px;
    vertical-align: top;
    margin-right: 10px;
  }
  .fb_iframe_widget > span {
      vertical-align: baseline !important;
  }
}

.box-navi_single {
  margin: 2rem 0;
  display: flex;
  justify-content: space-between;
  .page-next a:hover,
  .page-prev a:hover,
  .page-index a:hover {
    opacity: .6;
  }
  .page-next.none,
  .page-prev.none {
    opacity: .3;
  }
  @media print, screen and(min-width: $screen-sm-min) {
    margin: 5rem 0;
    justify-content: space-around;
  }
}


.side-box {
  
  .widget {
    margin-bottom: 3rem;
    .widget-title {
      @include fontsize(16);
      border-bottom: solid 1px rgba($color-text-light,.2);
      padding-bottom: 1rem;
//      color:$color-main;
      font-weight: bold;
      margin: 0 0 1em;
    }
    ul {
      margin: 0 0 1rem 0;
      padding-left: 1rem;
      list-style-type: none;
      li {
        @include fontsize(12);
        margin-bottom: 1em;
        a {
          color:$color-text;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  @media print, screen and(min-width: $screen-sm-min) {
    padding-left: 50px;  
  }
}