@charset "utf-8";

//
// ワードプレス
//
// この投稿を先頭に固定表示
.sticky {
  position: relative;
  border: solid 2px $color-accent;
  background-color: rgba($color-accent,.02);
  padding: 1rem;
  &:after {
    @include fontsize(14);
    font-weight: bold;
    display: block;
    top:0px;
    right:0px;
    position: absolute;
    content: "PICK UP!";
    background-color:$color-accent;
    color:$color-white;
    line-height: 1;
    padding:0.5em 1em;
  }
}
//管理人自身のコメントに対するCSSクラス
.bypostauthor {
  background-color:#eee;
}
//読み上げソフトに対応したスタイル
.screen-reader-text {
  display: none;
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
}
//文字寄せ
img.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.wp-caption.aligncenter,
img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
img.alignright {
  display: inline;
}

img.alignleft {
  display: inline;
}
.alignright {
  margin-left:1rem;
  float: right;
  clear:both;
}
.alignleft {
  margin-right:1rem;
  float: left
}

/***** 画像キャプションコードはじまり *****/
.wp-caption {
  display:block;
  max-width:100%;
  height:auto;
  padding: 0;
  margin-bottom: 1rem;
}
 
.wp-caption img {
  max-width: 100%;
  display:block;
  margin: 0 auto;
  margin-bottom: 0;
  padding: 0;
  border: 0 none;
}
 
.wp-caption p.wp-caption-text {
    @include fontsize(14);
    background-color: $color-white;
    padding: .5em;
    margin: 0;
}

.youtube {
  @include clearfix();
  position:relative;
  width:100%;
  padding-top:56.25%;
}
.youtube iframe{
  position:absolute;
  top:0;
  right:0;
  width:100%;
  height:100%;
}

/**
 * 14.2 Galleries
 */

.gallery {
  margin-bottom: 1.6em;
}

.gallery-item {
  display: inline-block;
  padding: 1.79104477%;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  @media (min-width: $screen-sm-min) { max-width: 50%;}
}
.gallery-columns-3 .gallery-item {
  @media (min-width: $screen-sm-min) {max-width: 33.33%;  }
}
.gallery-columns-4 .gallery-item {
  @media (min-width: $screen-sm-min) {max-width: 25%;}
}
.gallery-columns-5 .gallery-item {
  @media (min-width: $screen-sm-min) { max-width: 20%;}
}
.gallery-columns-6 .gallery-item {
  @media (min-width: $screen-sm-min) {max-width: 16.66%;}
}
.gallery-columns-7 .gallery-item {
  @media (min-width: $screen-sm-min) {max-width: 14.28%;}
}
.gallery-columns-8 .gallery-item {
  @media (min-width: $screen-sm-min) {max-width: 12.5%;}
}
.gallery-columns-9 .gallery-item {
  @media (min-width: $screen-sm-min) {max-width: 11.11%;}
}
.gallery-icon img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}
.gallery-caption {
  color: #707070;
  color: rgba(51, 51, 51, 0.7);
  display: block;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.5;
  padding: 0.5em 0;
}
.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
  display: none;
}
//
// youtube 埋め込み
//
span.embed-youtube {
  position: relative;
  width: 100%;
  max-width: 650px;
  padding-top: 56.25%;
}
 
span.embed-youtube iframe.youtube-player {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

//
// コメント
//
.comments-area {
  padding-top:50px;
  .comments-title {
    @include fontsize(16);
  }
  .comment-list {
    list-style-type: none;
    margin: 0;
  }
  .children {
    list-style-type: none;
    margin: 0;
  }
  .comment {
    position: relative;
//    background-color: #fafafa;
    border: solid 1px #aaa;
    padding:10px;
    margin-bottom: 10px;
  }
  .comment-meta {
    @include clearfix();
    .comment-author {
      float: left;
    }
    .comment-metadata {
      @include fontsize(12);
      float: right;
    }
    .comment-awaiting-moderation {
      @include fontsize(12);
      float: right;
    }
  }
  .comment-content {
//    padding:0.5em;
//    border: solid 1px #aaa;
//    background-color: #fff;
    p {
      margin-bottom: 0;
    }
    margin-bottom: 10px;
  }
  .comment.even {
//    background-color: #f2eeee;
  }
  .comment-reply-link {
    @include fontsize(12);
    display: inline-block;
    border: solid 1px #aaa;
    background-color: #ccc;
    color:$color-text;
    text-decoration: none;
    line-height: 1;
    padding:0.5em 1em;
    margin: 0.5em 0;
    &:hover {
      background-color: #eee;
    }
  }
}
// コメントフォーム
.comment-respond {
  border-top: dotted 1px #aaa;
  .comment-reply-title {
    @include fontsize(16);
    font-weight: bold;
  }
}
#cancel-comment-reply-link {
  @include fontsize(12);
  display: inline-block;
  border: solid 1px #aaa;
  background-color: #ccc;
  color:$color-text;
  text-decoration: none;
  line-height: 1;
  padding:0.5em 1em;
  margin-left: 20px;
  &:hover {
    background-color: #eee;
  }
}
.comment-form-author,
.comment-form-email,
.comment-form-url {
  @include clearfix();
  margin: 10px 0;
  label {
    display: block;
    width:150px;
    float:left;
  }
  input {
    float:left;
  }
}
.post-tags,
.post-categories {
  display: block;
  margin: 0;
  padding:0;
  list-style-type: none;
  li {
    display: block;
    float: left;
    margin: 0 .25em 0 0;
  }
  a {
    display: block;
    @include fontsize(12);
    line-height: 1.5rem;
    letter-spacing: 0.1em;
    padding: 0 1em;
    background-color: rgba($color-text,.5);
    color:#fff;
    text-decoration: none;
    &:hover {
      background-color: rgba($color-text,.3);
    }
  }
}
//作業調整用 アーカイブリスト表示用
.post-list {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: solid 1px #ccc;
}
.post-list-thumbnail {
  float:right;
}

// シングルでのアイキャッチ画像表示
.post-thumbnail {
  text-align: center;
}