@charset "UTF-8";

// ページナビ
.pagenate,
.nav-links,
.wp-pagenavi {
  @include clearfix();
  clear: both;
  padding: 10px 0;
  text-align: center;

  .first:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f100';
    margin: 0 .5em 0 0;
  }

  .last:after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f101';
    margin: 0 0 0 .5em;
  }

  .prev:before {
    content: "\f104";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    margin: 0 .5em 0 0;
  }

  .next:after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f105';
    margin: 0 0 0 .5em;
  }

  a,
  .current {
    background-color: $color-white;
    border: solid 1px $color-main;
    vertical-align: middle;
    box-shadow: 0 3px 3px -3px rgba($color-black,.5);
    border-radius: 2px;
    box-sizing: border-box;
    color: $color-main;
    display: inline-block;
    line-height: 1;
    margin: .05em;
    padding: .5em;
    text-decoration: none;
    transition: background-color .5s linear, color .2s linear, border-color .2s linear;
  }

  a:hover {
    background-color: $color-main;
    color: $color-white;
  }

  .current {
    background-color: $color-main;
    border: solid 1px $color-main;
    color: $color-white;
  }

  .first,
  .last,
  .current.page-numbers,
  .page-numbers {
    display: none;
    &.prev,
    &.next {
      display: inline-block;
    }
    @media (min-width: $screen-sm-min) {
      display: inline-block;
    }
  }
}

// ページナビ
.page-links {
  @include clearfix();
  clear: both;
  padding: 10px 0;
  text-align: center;

  .page-numbers {
    background-color: $color-text;
    border: solid 1px $color-text;
    color: $color-white;
    display: inline-block;
    line-height: 1;
    margin: .05em;
    padding: .5em;
  }

  a .page-numbers {
    background-color: $color-white;
    border: solid 1px $color-text;
    color: $color-text;
    display: inline-block;
    text-decoration: none;
    transition: background-color .5s linear, color .2s linear, border-color .2s linear;

    &:hover {
      background-color: $color-text;
      color: $color-white;
    }
  }
}

// ページナビ
.page-navi {
  display: flex;
  flex-flow: row;
  padding: 2em 0;

  .page-next,
  .page-prev {
    display: flex;
    width: 50%;
    padding-right: .25em;
    a {
      @include fontsize(12);
      line-height: 1.4;
      background-color: $color-white;
      border: solid 1px $color-text;
      color: $color-text;
      display: block;
      padding: .5em .5em .5em 1.5em;
      position: relative;
      text-decoration: none;
      width: 100%;
      box-shadow: 0 3px 3px -3px rgba($color-black,.5);
      transition: background-color .2s linear;

      .title {
        display: block;
        font-weight: bold;
      }

      &:before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: '\f053';
        display: block;
        left: .5em;
        position: absolute;
        top: .5em;
      }

      &:hover {
        background-color: rgba($color-text,.025);
//        color: $color-white;
      }
    }
  }

  .page-next {
    padding-left: .25em;
    a {
      padding: .5em 1.5em .5em .5em;
      &:before {
        content: '\f054';
        left: inherit;
        right: .5em;
      }
    }
  }
  @media print, screen and(min-width: $screen-md-min) {
    .page-next,
    .page-prev {
      display: flex;
      width: 50%;
      padding-right: .5em;
      a {
        @include fontsize(14);
      }
    }
    .page-next {
      padding-right: 0;
      padding-left: .5em;
    }
  }
}
