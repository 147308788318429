.box-division {
  .info-division {
    
  }
  .photo-division {
    
  }
  .catch-division {
    @include fontsize(18);
    font-weight: bold;
    color:$color-main;
//    line-height: 1.2;
    line-height: 1.75;
    margin-bottom: 1em;
  }
  .lead-division {
    color: $color-text-light;
  }
  @media print, screen and(min-width: $screen-sm-min) {
   .info-division {
      float:left;
      width:50%;
      padding-right: 1rem;
       &.full {
        float:none;
         width: auto;
         padding: 0;
         .catch-division {
           text-align: center;
         }
       }
    }
    .photo-division {
      float: right;
      width:50%;
      padding-left: 1rem;
    }
    .catch-division {
      @include fontsize(20);
      font-weight: bold;
      color:$color-main;
//      line-height: 1.2;
      line-height: 1.8;
    }
    .lead-division {

    } 
  }
  @media print, screen and(min-width: $screen-md-min) {
    .catch-division {
      @include fontsize(28);
    }
    .lead-division {
      @include fontsize(18);
    } 
  }
}

.bg-outline {
//  background-color:rgba($color-gray,.18);
  background-color:rgba($color-gray,.4);
  padding-bottom: 100px;
}

.division-h3 {
  text-align: center;
  @include fontsize(16);
  padding: 2em 0 1em 0;
  margin: 0 0 2em 0;
  border-bottom: solid 1px $color-gray;
  small {
    @include fontsize(12);
    display: block;
    margin-bottom: .5em;
  }
  @media print, screen and(min-width: $screen-sm-min) {
    @include fontsize(22);
    small {
      @include fontsize(16);
    }
  }
}
.division-h4 {
  @include fontsize(16);
  text-align: center;
  margin: 1em 0;
  @media print, screen and(min-width: $screen-sm-min) {
    @include fontsize(24);
  }
}

.list-technical {
  list-style-type: none;
  margin: 0;
  padding: 2rem;
  background-color: $color-white;
  li {
    display: inline-block;
    margin-right: 1rem;
  }
}