.page-head {
//  background-image: url('../img/corporate/corporate-head.jpg');
  background-color: rgba($color-main,.05);
  background-size: cover;
  height: 180px;
  margin-bottom: 2rem;
  .title-page-head {
    margin: 60px 0 0 0;
    @include fontsize(20);
    color:$color-main;
    letter-spacing: .1em;
    small {
      @include fontsize(12);
      color: $color-text;
      letter-spacing: 0;
      margin-bottom: .5em;
      display: block;
    }
  }
  @media print, screen and(min-width: $screen-md-min) {
    height: 544px;
    margin-bottom: 3rem;
    .title-page-head {
      margin: 240px 0 0 0;
      @include fontsize(33);
      letter-spacing: .25em;
      small {
        @include fontsize(16);
      }
    } 
  }
  &.corporate {
    background-image: url('../img/corporate/corporate-head.jpg');
    background-position: bottom center;
  }
  &.subpage {
    .title-page-head {
      position: relative;
      background-color: rgba($color-main,.69);
      max-width: 300px;
      padding: 1em .25em 1em 1em;
      color:$color-white;
      small {
        color:$color-white;
      }
      &:before {
        display: block;
        content: '';
        position: absolute;
        width:1920px;
        height: 100%;
        top:0;
        left: -1920px;
        background-color: rgba($color-main,.69);
      }
    }
    @media print, screen and(min-width: $screen-md-min) {
      height: 368px;
      .title-page-head {
        max-width: 590px;
        margin: 120px 0 0 0;
      }
    }
  }
  &.corporate.subpage {
    background-image: url('../img/corporate/corporate-head.jpg');
    background-position: bottom center;
  }
  &.company {
    background-image: url('../img/corporate/company-head.jpg');
    .title-page-head {
      color:$color-white;
      small {
        color:$color-white;
      }
    }
  }
  &.message {
    background-image: url('../img/message/message-head.jpg');
    .title-page-head {
      color:$color-white;
      small {
        color:$color-white;
      }
    }
  }
  &.csr {
    background-image: url('../img/csr/csr-head.jpg');
    .title-page-head {
      color:$color-white;
      small {
        color:$color-white;
      }
    }
  }
  &.place {
    background-image: url('../img/place/place-head.jpg');
    .title-page-head {
      color:$color-white;
      small {
        color:$color-white;
      }
    }
  }
  &.chotatsu {
    background-image: url('../img/chotatsu/chotatsu-head.jpg');
    .title-page-head {
      color:$color-white;
      small {
        color:$color-white;
      }
    }
  }
  &.history {
    background-image: url('../img/history/history-head.jpg');
    .title-page-head {
      color:$color-white;
      small {
        color:$color-white;
      }
    }
  }
  &.technology {
    background-image: url('../img/teach/teach-head.jpg');
    .title-page-head {
      color:$color-white;
      small {
        color:$color-white;
      }
    }
  }
  &.business {
    background-image: url('../img/business/business-head.jpg');
    .title-page-head {
      color:$color-white;
      small {
        color:$color-white;
      }
    }
  }
  &.division {
    .title-page-head {
      position: relative;
      background-color: rgba($color-main,.69);
      max-width: 350px;
      padding: 1em .25em 1em 1em;
      color:$color-white;
      small {
        color:$color-white;
      }
      &:before {
        display: block;
        content: '';
        position: absolute;
        width:1920px;
        height: 100%;
        top:0;
        left: -1920px;
        background-color: rgba($color-main,.69);
      }
    }
    @media print, screen and(min-width: $screen-md-min) {
      height: 368px;
      .title-page-head {
        max-width: 550px;
        margin: 120px 0 0 0;
      }
    }
  }
  &.electrical {
    background-image: url('../img/business/electrical-head.jpg');
  }
  &.equipment {
    background-image: url('../img/business/equipment-head.jpg');
  }
  &.public {
    background-image: url('../img/business/public-head.jpg');
  }
  &.power {
    background-image: url('../img/business/power-head.jpg');
  }
  &.energy {
    background-image: url('../img/business/energy-head.jpg');
  }
  &.overseas {
    background-image: url('../img/business/overseas-head.jpg');
  }
  &.biru {
    background-image: url('../img/teach/biru-head.jpg');
  }
  &.global {
    background-image: url('../img/global/global-head.jpg');
    .title-page-head {
      @include fontsize(14);
      line-height: 2.12;
      color:$color-white;
      text-align: center;
      small {
        color:$color-white;
      }
    }
    @media print, screen and(min-width: $screen-sm-min) {
      .title-page-head {
        @include fontsize(18);
        
      }
    }
    @media print, screen and(min-width: $screen-md-min) {
      .title-page-head {
        @include fontsize(25);
        margin: 220px 0 0 0;
      }
    }
  }
  &.works {
    background-image: url('../img/works/works-head.jpg');
    .title-page-head {
      @include fontsize(14);
      line-height: 2.12;
      color:$color-white;
      text-align: center;
      small {
        color:$color-white;
      }
    }
    @media print, screen and(min-width: $screen-sm-min) {
      .title-page-head {
        @include fontsize(18);
        
      }
    }
    @media print, screen and(min-width: $screen-md-min) {
      .title-page-head {
        @include fontsize(25);
        margin: 250px 0 0 0;
      }
    }
  }
  &.works-list {
    height: auto;
    background-color: transparent;
    .title-page-head {
      margin: 0 0 0 0;
      font-weight: bold;
      @include fontsize(20);
      line-height: 2.12;
      color:$color-main;
      text-align: center;
      small {
        color:$color-white;
      }
    }
    @media print, screen and(min-width: $screen-sm-min) {
      .title-page-head {
        @include fontsize(28);
        margin: 50px 0 0 0;
      }
    }
    @media print, screen and(min-width: $screen-md-min) {
      .title-page-head {
        @include fontsize(28);
//        margin: 250px 0 0 0;
      }
    }
  }
  &.vision {
    background-image: url('../img/vision/vision-head.jpg');
    height: 300px;
    .title-page-head {
      text-align: center;
      margin: 100px 0 0 0;
    }
    @media print, screen and(min-width: $screen-md-min) {
      height: 544px;
      .title-page-head {
        text-align: center;
        margin: 200px 0 0 0;
      } 
    }
  }
  &.technical {
    background-image: url('../img/strong/technical-head.jpg');
    background-position: center center;
    height: 300px;
    position: relative;
    .title-page-head {
      position: relative;
      display: inline-block;
      @include fontsize(25);
      font-family: $font-family-abel;
      background-color: rgba(#2d2b7d,.82);
      color:$color-white;
      line-height: 1;
      letter-spacing: 0;
      padding: 10px;
      margin: 100px 0 0 0;
      &:before {
        content: '';
        display: block;
        position: absolute;
        background-color: rgba(#2d2b7d,.82);
        height: 100%;
        width: 1920px;
        top:0;
        left: -1920px;
      }
    }
    .catch {
      color:$color-white;
      @include fontsize(20);
    }
    @media print, screen and(min-width: $screen-md-min) {
      height: 544px;
      .title-page-head {
        @include fontsize(46);
        margin: 200px 0 1rem 0;
        padding: 20px 60px 20px 0;
      }
      .catch {
        @include fontsize(28);
      }
    }
  }
  &.solution {
    background-image: url('../img/strong/solution-head.jpg');
    background-position: center center;
    height: 300px;
    position: relative;
    .title-page-head {
      position: relative;
      display: inline-block;
      @include fontsize(25);
      font-family: $font-family-abel;
      background-color: rgba(#00538d,.82);
      color:$color-white;
      line-height: 1;
      letter-spacing: 0;
      padding: 10px;
      margin: 100px 0 0 0;
      &:before {
        content: '';
        display: block;
        position: absolute;
        background-color: rgba(#00538d,.82);
        height: 100%;
        width: 1920px;
        top:0;
        left: -1920px;
      }
    }
    .catch {
      color:$color-white;
      @include fontsize(20);
    }
    @media print, screen and(min-width: $screen-md-min) {
      height: 544px;
      .title-page-head {
        @include fontsize(46);
        margin: 200px 0 1rem 0;
        padding: 20px 60px 20px 0;
      }
      .catch {
        @include fontsize(28);
      }
    }
  }
  &.knowledge {
    background-image: url('../img/strong/knowledge-head.jpg');
    background-position: center center;
    height: 300px;
    position: relative;
    .title-page-head {
      position: relative;
      display: inline-block;
      @include fontsize(25);
      font-family: $font-family-abel;
      background-color: rgba(#008080,.82);
      color:$color-white;
      line-height: 1;
      letter-spacing: 0;
      padding: 10px;
      margin: 100px 0 0 0;
      &:before {
        content: '';
        display: block;
        position: absolute;
        background-color: rgba(#008080,.82);
        height: 100%;
        width: 1920px;
        top:0;
        left: -1920px;
      }
    }
    .catch {
      color:$color-white;
      @include fontsize(20);
    }
    @media print, screen and(min-width: $screen-md-min) {
      height: 544px;
      .title-page-head {
        @include fontsize(46);
        margin: 200px 0 1rem 0;
        padding: 20px 60px 20px 0;
      }
      .catch {
        @include fontsize(28);
      }
    }
  }
  &.core {
    background-image: url('../img/strong/core-head.jpg');
    height: 300px;
    .core-head {
      margin: 70px 0 0 0;
      color:$color-white;
      .title {
        @include fontsize(16);
        text-align: center;
        letter-spacing: .25em;
        margin: 0;
      }
    }
    .core-point {
      list-style-type: none;
      padding: 0;
      margin: 1em 0;
      text-align: center;
      @include fontsize(16);
      font-family: $font-family-abel;
      //font-weight: bold;
      li {
        display: inline-block;
        &:before {
          content:'/';
          margin: 0 1em;
        }
        &:first-child:before {
          display: none;
        }
      }
    }
    .core-lead {
      @include fontsize(14);
      text-align: center;
    }
    .title-page-head {
      display: block;
      color:$color-white;
      text-align: center;
      max-width: 300px;
      margin: 90px auto .5em auto;
      padding-bottom: .5em;
      border-bottom: solid 1px $color-white;
    }
    .page-catch {
      @include fontsize(18);
      color:$color-white;
      text-align: center;
    }
    @media print, screen and(min-width: $screen-md-min) {
      height: 544px;
      .core-head {
        margin: 170px auto .5em auto;
        .title {
          @include fontsize(25);
        }
      }
      .core-point {
        @include fontsize(45);
        margin: .5em 0;
      }
      .core-lead {
        @include fontsize(20);
      }
      .title-page-head {
        text-align: center;
        margin: 170px auto .5em auto;
      }
      .page-catch {
        @include fontsize(24);
        color:$color-white;
        text-align: center;
      }
    }
  }
  &.future {
    position: relative;
    background-image: url('../img/future/future-head.jpg');
    height: 300px;
    margin-bottom: 0;
    .title-page-head {
      text-align: center;
      margin: 50px 0 0 0;
    }
    .tab-menu {
      position: absolute;
      padding: 0 50px;
      bottom:0;
      left: 0;
      background:linear-gradient(to top,rgba(#000,1) 0%, rgba(#000,1) 20%, rgba($color-black,0) 10%,);
      width:100%;
      text-align: center;
      img {
        position: relative;
        bottom:0;
      }
    }
    @media print, screen and(min-width: $screen-md-min) {
      height: 544px;
      .title-page-head {
        text-align: center;
        margin: 170px 0 0 0;
      } 
    }
  }
}