.contents-page {
  @include fontsize(14);
}
// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
  font-feature-settings: 'palt';
  font-weight: normal;
  line-height: 1;
  margin: 0;
  padding: 0;

  small {
    font-size: 70%;
  }
}

h1,
h2,
h3 {
  margin-top: 1em;
  margin-bottom: 1em;
}

h1 {
  @include fontsize(48);
}

h2 {
  @include fontsize(36);
}

h3 {
  @include fontsize(28);
}

h4,
h5,
h6 {
  margin-top: 1em;
  margin-bottom: 1em;
}

h4 {
  @include fontsize(20);
}

h5 {
  @include fontsize(16);
}

h6 {
  @include fontsize(14);
}

// Body text
p {
  font-feature-settings: 'pkna';
  line-height: $line-height-base;
  margin-top: 0;
  margin-bottom: 1em;
}
// Marker Unde Line
mark {
  background: linear-gradient(transparent 60%, $color-yellow 60%);
  padding: 0;
}
// Code
code {
  background-color: rgba($color-gray,.5);
  padding: .1em .2em;
}
// Lists
ul,
ol {
  margin-bottom: 1em;

  ul,
  ol {
    margin-bottom: 0;
  }
}
// Description Lists
dl {
  margin: 0 0 1em;
}

dt,
dd {
  line-height: $line-height-base;
}

dt {
  border-bottom: solid 2px $color-sub;
  font-weight: bold;
  letter-spacing: .1em;
  padding: .2em .5em;
}

dd {
  margin-bottom: 1em;
  margin-left: 0; // Undo browser default
  padding: .5em;
}
// Blockquotes
blockquote {
  border-left: .35em solid $color-gray;
  margin: 0 0 1em;
  padding: 1em;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-top: 0;
  }
  p:last-child,
  ul:last-child,
  ol:last-child {
    margin-bottom: 0;
  }

  footer {
    font-size: 80%;
  }
}
// pre
pre {
  background-color: rgba($color-gray,.25);
  border: 1px solid $color-gray;
  font-family: Consolas, Monaco, Lucida Console, monospace;
  line-height: 1.5;
  margin-bottom: 1em;
  overflow: auto;
  padding: .5em;
  code {
    background-color: $color-black;
    color: $color-gray;
    display: block;
    margin: -.5em;
    padding: .5em;
  }
}

// table
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 1em;
  width: 100%;

  caption {
    text-align: left;
  }

  th,
  td {
    border-bottom: 1px solid $color-gray;
    padding: .5em .75em;
    vertical-align: top;
  }

  th {
    font-weight: bold;
    text-align: left;
  }

  td {
    
  }
 
}

// table
table.tbl-company {
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 1em;
  width: 100%;

  caption {
    text-align: left;
  }

  th,
  td {
    border-bottom: 1px solid $color-gray;
    padding: .5em .5em;
    vertical-align: top;
    display: block;
  }

  th {
    padding-bottom: 0;
    border-bottom: 0;
    font-weight: bold;
    text-align: left;
  }

  td {
    padding-left: 1.5em;
    border-bottom: 1px solid $color-gray;
  }
  @media print, screen and (min-width: $screen-md-min) {
    th,
    td {
      border-bottom: 1px solid $color-gray;
      padding: .5em .75em;
      display: table-cell;
    }
  }
}
table.tbl-company_employ {
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 1em;
  width: 100%;

  caption {
    text-align: left;
  }

  th,
  td {
    border-bottom: 1px solid $color-gray;
    padding: .5em .5em;
    vertical-align: top;
    display: block;
  }

  th {
    padding-bottom: 0;
    border-bottom: 0;
    font-weight: bold;
    text-align: left;
  }

  td {
    padding-left: 1.5em;
    border-bottom: 1px solid $color-gray;
  }
  @media print, screen and (min-width: $screen-sm-min) {
    th,
    td {
      border-bottom: 1px solid $color-gray;
      padding: .5em .75em;
      display: table-cell;
    }
    td {
      text-align: right;
    }
  }
}

