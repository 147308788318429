.box-front-news {
  margin-bottom: 40px;
  @media print, screen and(min-width: $screen-md-min) {
    padding: 0 3rem;
    margin-bottom: 0;
  }
}
.box-front-recruit {
  @media print, screen and(min-width: $screen-md-min) {
    padding: 0 3rem;
  }
}