@charset "UTF-8";

.main-menu {
  display: none;
  @media print, screen and(min-width: $screen-sm-min) {
    display: block;
    float: left;
    .menu {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      .menu-item {
        margin-right: 1rem;
        a {
          @include fontsize(14);
          display: inline-block;
          text-decoration: none;
          color:$color-text;
          transition: color .5s linear;
          position: relative;
            &:after {
              position: absolute;
              bottom:0;
              left: 50%;
              content: '';
              display: block;
              width: 0%;
              height: 2px;
              background-color: transparent;
              transition: all .2s linear;
            }
          &:hover {
            &:after {
              position: absolute;
              bottom:0;
              left: 5%;
              content: '';
              display: block;
              width: 90%;
              height: 2px;
              background-color: $color-main;
            }
//            color: rgba($color-text,.7);
          }
        }
      }
    }
  }
  @media print, screen and(min-width: $screen-md-min) {
    .menu {
      .menu-item {
        margin-right: 3rem;
        a {
          @include fontsize(15);
        }
      }
    }
  }
}

.second-menu {
  display: none;
  @media print, screen and(min-width: $screen-sm-min) {
    display: block;
    float: right;
    .menu {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      .menu-item {
        margin-left: 1em;
        a {
          @include fontsize(10);
          display: inline-block;
          text-decoration: none;
          color:$color-text;
          transition: color .5s linear;
          &:before {
            display: inline-block;
            vertical-align: middle;
            margin-top: -3px;
            margin-right: .25em;
            content: '';
            background-image: url('../img/common/icon-right-black.svg');
            width: 15px;
            height: 15px;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  @media print, screen and(min-width: $screen-md-min) {
    .menu {
      .menu-item {
        margin-left: 1em;
        a {
          @include fontsize(12);
          &:before {
            display: inline-block;
            vertical-align: middle;
            margin-top: -3px;
            margin-right: .5em;
            content: '';
            background-image: url('../img/common/icon-right-black.svg');
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}

.lang-menu {
  display: none;
  @media print, screen and(min-width: $screen-sm-min) {
    display: block;
    position: absolute;
    top: 6px;
    right: ($gutter / 2);
    .menu {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      .menu-item {
        a {
          @include fontsize(13);
          display: inline-block;
          text-decoration: none;
          color:$color-main;
          background-color: $color-gray;
          border-left: solid 1px $color-main;
          transition: color .5s linear;
          padding-right: 1em;
          &:before {
            display: inline-block;
            vertical-align: middle;
            margin-top: -3px;
            margin-right: .5em;
            content: '';
            background-image: url('../img/common/icon-right-blue.svg');
            width: 15px;
            height: 15px;
          }
          
          &:hover {
            color:$color-white;
            background-color: rgba($color-main,.7);
            &:before {
              background-image: url('../img/common/icon-right-white.svg');
            }
          }
        }
        &.current a {
          color:$color-white;
          background-color: $color-main;
          &:before {
            background-image: url('../img/common/icon-right-white.svg');
          }
        }
      }
    }
  }
}

.foot-menu {
  display: block;
  .menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .menu-item {
      &:first-child a {
        border-left: 0;
      }
      a {
        position: relative;
        @include fontsize(13);
        display: inline-block;
        text-decoration: none;
        color:$color-text;
        padding: 0 1em;
        border-left: solid 1px $color-text;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.sitemap-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content:flex-start;
  .menu {
    list-style-type: none;
    margin: 0;
    padding: 0 3rem 0 0;
    &:last-child {
      padding: 0;
    }
    .menu-item {
      a {
        position: relative;
        @include fontsize(13);
        display: inline-block;
        text-decoration: none;
        font-weight: bold;
        color:$color-text;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .child-menu {
      list-style-type: none;
      margin: 0;
      padding: 0;
      .menu-item {
        line-height: 1.4;
        &:before {
          content: '・';
        }
        a {
          font-weight: normal;  
        }
      }
    } 
    .large-menu {
      list-style-type: none;
      margin: 1em 0;
      padding: 0;
      .menu-item {
        line-height: 1.4;
        a {
//          font-weight: normal;  
        }
      }
    } 
  }
  @media print, screen and(min-width: $screen-md-min) {
    padding-top: 1.15rem;
  }
}

.cover {
  cursor: pointer;
  z-index: 290;
  background-color: rgba($color-black,.5);
  background:linear-gradient(to left,rgba($color-black,.8) 80%, rgba($color-black,.1) 90%,);
  position: fixed;
  top:0;
  right: 0;
  width: 100%;
  height: 100%;
  transition: all 0.2s;
  &.close {
    right: -100%;
  }
  @media print, screen and(min-width: $screen-sm-min) {
    right: -100%;
  }
  @media print {
    display: none;
  }
}
.sp-menu {
  z-index: 300;
  position: fixed;
  top:0;
  right: 0;
  background-color: rgba($color-black,.7);
  height: 100%;
  width:85%;
  padding: 1rem;
  transition: all 0.4s;
  overflow: auto;
  &.close {
    right: -100%;
  }
  .sp-menu-title {
    color:$color-white;
    @include fontsize(14);
    font-weight: bold;
    margin: 0 0 .5em 0;
    position: relative;
  }
  .menu {
    @include clearfix();
    margin: 0 0 1rem 0;
    padding: 0;
    list-style-type: none;
    justify-content: space-around;
    .menu-item {
      list-style-type: none;
      position: relative;
      &:first-child {
//        border-top: solid 1px $color-gray;
      }
      .child-menu {
        padding: 0 0 0 1rem;
        display: none;
        .menu-item {
          &:first-child {
            border-top:0;
          }
        }
      }
      .btn-child {
        position: absolute;
        top:0;
        right: 0;
        height: 43px;
        width:43px;
        background-image: url('../img/common/plus-white.svg');
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
        &.open {
          background-image: url('../img/common/minus-white.svg');
        }
      }
      a {
        border: solid 1px rgba($color-gray,.4);
        margin-bottom: 5px;
        @include fontsize(14);
        position: relative;
        display: block;
        color:$color-white;
        text-decoration: none;
        line-height: 1;
        padding:1em 1em 1em 2em;
        &:before {
          display: inline-block;
          content:'';
          position: absolute;
          top:0;
          left: 0;
          height: 42px;
          width:25px;
          background-image: url('../img/common/arrow-white.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
        &:hover {
        
        }
      }
    }
  }
  @media print, screen and(min-width: $screen-sm-min) {
    right: -100%;
  }
}
.menu-btn {
  z-index: 1000;
//  position: fixed;
  position: absolute;
  top:0;
  right:0;
  width:56px;
  height:56px;
  padding-top:0;
  font-size:10px;
  line-height: 1;
  text-align: center;
  background-color:$color-main;
  color:$color-main;
  cursor: pointer;
  transition: all 0.4s;
  
  @media print, screen and (min-width: $screen-sm-min) {
    display:none;
  }
}

.bg-page-menu {
  background-color: $color-gray;
}
.page-menu {
  display: none;
  @media print, screen and(min-width: $screen-md-min) {
    display: block;
    float: left;
    .menu {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      .menu-item {
        position: relative;
        height: 100%;
        &:first-child:before {
          display: none;
        }
        &.current a {
          background-color: darken($color-gray,10%);
        }
        &:before {
          position: absolute;
          top:15%;
          display: inline-block;
          content: '';
          width:1px;
          height: 70%;
          background-color: darken($color-gray,40%);
        }
        a {
          height: 100%;
          @include fontsize(12);
          line-height: 1.2;
          display: inline-block;
          text-decoration: none;
          color:$color-text;
          transition: background-color .2s linear;
          display: block;
          padding: .75em 1em;
          &:hover {
            background-color: darken($color-gray,5%);
          }
        }
      }
    }
  }
}

.bg-category-menu {
  background-color: rgba($color-gray,.4);
  margin-bottom: 3rem;
  display: none;
  @media print, screen and(min-width: $screen-sm-min) {
    display: block;
  }
}
.category-menu {
  display: none;
  @media print, screen and(min-width: $screen-sm-min) {
  display: block;
  .menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content:center;
    .menu-item {
      position: relative;
      height: 100%;
      &:first-child:before {
        position: absolute;
        top:15%;
        left: 0;
        display: inline-block;
        content: '';
        width:1px;
        height: 70%;
        background-color: darken($color-gray,10%);
      }
      &.current a {
        color:$color-text;
      }
      &:after {
        position: absolute;
        top:15%;
        right: 0;
        display: inline-block;
        content: '';
        width:1px;
        height: 70%;
        background-color: darken($color-gray,10%);
      }
      a {
        height: 100%;
        @include fontsize(14);
        display: inline-block;
        text-decoration: none;
        color: darken($color-gray,10%);
        transition: color .2s linear, background-color .2s linear;
        display: block;
        padding: .5em 1em;
        &:hover {
          background-color: darken($color-gray,5%);
          color: $color-text;
        }
      }
    }
  }
  }
}

.bg-navigation-menu {
  padding: 1rem 0;
  background-color: $color-gray;
  @media print, screen and(min-width: $screen-sm-min) {
    margin-bottom: 3rem;
  }
  @media print, screen and(min-width: $screen-md-min) {
    display: none;
  }
}
.navigation-menu {
  display: block;
  .menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
//    justify-content: flex-start;
    .menu-item {
//      display: flex;
//      width: 50%;
      flex: 0 0 50%;
//      height: 100%;
      a {
        height: 100%;
        @include fontsize(12);
        display: block;
        text-decoration: none;
        padding: 1rem 0;
        text-align: center;
        border: solid 1px $color-gray;
        background-color: $color-white;
        color:$color-text;
        transition: color .5s linear;
//        &:before {
//          display: inline-block;
//          vertical-align: middle;
//          margin-top: -3px;
//          margin-right: .5em;
//          content: '';
//          background-image: url('../img/common/icon-right-black.svg');
//          width: 15px;
//          height: 15px;
//        }
      }
    }
  }
}

.menu-place {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  .menu-item {
    flex: 0 0 100%;
    .item-container {
      padding: 0 10px 20px 10px;
    }
    a {
      position: relative;
      display: block;
      border: solid 1px $color-text;
      font-weight: bold;
      color:$color-text;
      text-decoration: none;
      padding: .5em 0 .5em 2em;
      &:before {
        display: block;
        position: absolute;
        top:12px;
        left: 8px;
        content: '';
        background-image: url('../img/common/arrow-down.svg');
        width:15px;
        height: 15px;
      }
      &:hover {
        opacity: .7;
      }
    }
  }
  @media print, screen and(min-width: $screen-sm-min) {
    .menu-item {
      flex: 0 0 33.3%;
    }
  }
  @media print, screen and(min-width: $screen-md-min) {
    .menu-item {
      flex: 0 0 20%;
    }
  }
  @media print, screen and(min-width: $screen-lg-min) {
    margin: 0 -15px;
    .menu-item {
      flex: 0 0 20%;
      .item-container {
        padding: 0 15px 30px 15px;
      }
    } 
  }
}

.menu-csr {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  .menu-item {
    flex: 0 0 100%;
    .item-container {
      padding: 0 5px 10px 5px;
    }
    a {
      @include fontsize(14);
      position: relative;
      display: block;
      border: solid 1px $color-text;
      font-weight: bold;
      color:$color-text;
      text-decoration: none;
      padding: .5em 0 .5em 2em;
      &:before {
        display: block;
        position: absolute;
        top:12px;
        left: 8px;
        content: '';
        background-image: url('../img/common/arrow-down.svg');
        width:15px;
        height: 15px;
      }
      &:hover {
        opacity: .7;
      }
    }
  }
  @media print, screen and(min-width: $screen-sm-min) {
    .menu-item {
      flex: 0 0 30%;
    }
  }
  @media print, screen and(min-width: $screen-md-min) {
    .menu-item {
      flex: 0 0 25%;
      a {
        @include fontsize(16);
      }
    }
  }
  @media print, screen and(min-width: $screen-lg-min) {
    .menu-item {
      flex: 0 0 22%;
      .item-container {
        padding: 0 15px 30px 15px;
      }
    } 
  }
}

.menu-point {
  @include clearfix();
  list-style-type: none;
  margin: 0;
  padding: 0;
  .menu-item {
    display: block;
    width:33.33%;
    float: left;
    a {
      display: block;
      text-decoration: none;
      background-color: red;
      .photo {
        img {
          vertical-align: bottom;
        }
      }
      .title {
        font-family: $font-family-abel;
        color:$color-white;
        line-height: 1;
        text-align: center;
        padding: .5em 0;
        @include fontsize(16);
        @media print, screen and(min-width: $screen-sm-min) {
          @include fontsize(27);
        }
      }
      &:hover {
        opacity: .8;
      }
    }
    &.point01 a{
      background-color: rgba(#2d2b7d,1);
    }
    &.point02 a{
      background-color: rgba(#00538d,1);
    }
    &.point03 a{
      background-color: rgba(#008080,1);
    }
  }
}
.menu-point_top {
  @include clearfix();
  list-style-type: none;
  margin: 0;
  padding: 0;
  .menu-item {
    display: block;
    width:33.33%;
    float: left;
    a {
      display: block;
      text-decoration: none;
      background-color: red;
      .photo {
        img {
          vertical-align: bottom;
        }
      }
      .title {
        font-family: $font-family-abel;
        color:rgba($color-white,.4);
        line-height: 1;
        text-align: center;
        padding: .5em 0;
        @include fontsize(16);
        @media print, screen and(min-width: $screen-sm-min) {
          @include fontsize(27);
        }
      }
      &:hover {
        .title {
          color:rgba($color-white,.8);
        }
        opacity: .8;
      }
    }
    &.point01 a{
      background-color: rgba(#2d2b7d,.4);
    }
    &.point02 a{
      background-color: rgba(#00538d,.4);
    }
    &.point03 a{
      background-color: rgba(#008080,.4);
    }
    &.point01.current a{
      background-color: rgba(#2d2b7d,.8);
      .title {
        color:$color-white;
      }
    }
    &.point02.current a{
      background-color: rgba(#00538d,.8);
      .title {
        color:$color-white;
      }
    }
    &.point03.current a{
      background-color: rgba(#008080,.8);
      .title {
        color:$color-white;
      }
    }
    
  }
}
