.card {
//  display: flex;
  flex-direction: column;
  border: solid 1px lighten($color-gray,10%);
  box-shadow: 0 3px 3px -3px rgba($color-black,.4);
  height: 100%;
  .card-title {
    padding: .5rem 0;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }
  }
  .card-image {
    
  }
  .card-section {
    
  }
  a {
    height: 100%;
    display: block;
    text-decoration: none;
    color:$color-text;
    transition: background-color .2s linear;
    &:hover {
      background-color: rgba($color-gray,.15);
    }
  }
}


//
// TOPページ 各サブページへの移動
//
.card-top {
  border-bottom: solid 1px $color-gray;
  &.border-r {
    border-right: solid 1px $color-gray;
  }
  height: 100%;
  a {
    display: block;
    text-decoration: none;
    padding: 1.2rem 5px;
    &:hover {
      .title {
        color: $color-main;
        small {
          color: $color-main;
        }
      }
      .image {
        img {
          vertical-align: bottom;
          transform: scale(1.1);
          opacity: .6;
        }
      }
    }
  }
  .title {
    @include fontsize(12);
    padding: 0;
    line-height: 1.2;
    letter-spacing: .1em;
    color: $color-text;
    text-align: center;
//    font-weight: bold;
    small {
      @include fontsize(6);
      font-weight: normal;
      display: block;
      display: none;
      color: $color-gray-dark;
    }
  }
  .image {
    display: none;
    overflow: hidden;
    background-color: $color-black;
    img {
      transition: transform .2s linear,opacity .2s linear;
      vertical-align: bottom;
    }
  }
  
  @media print, screen and(min-width: $screen-sm-min) {
    border: 0;
    height: auto;
    &.border-r {
      border-right: 0;
    }
    a {
      display: block;
      padding: 0;
    }
    .title {
      @include fontsize(14);
      padding: 0 2.5rem .5rem 2.5rem;
      text-align: left;
      small {
        display: block;
        @include fontsize(8);
      }
    }
    .image {
      display: block;
    }
  }
  @media print, screen and(min-width: $screen-md-min) {
    .title {
      @include fontsize(16);
      padding: 0 2.5rem .5rem 2.5rem;
      small {
        @include fontsize(8);
      }
    }
  }
}
.card-top-sub {
  display: none;
  @media print, screen and(min-width: $screen-sm-min) {
    display: block;
    padding: 1rem 2.5rem 1rem 2.5rem;
    margin-bottom: 2rem;
    .list-link {
      list-style-type: none;
      margin: 0;
      padding: 0;
      .list-item {
        @include fontsize(12);
        line-height: 1.4;
        margin-bottom: .75em;
        a {
          display: inline-block;
          text-decoration: none;
          color: $color-gray-dark;
          position: relative;
          padding-left: 1em;
          &:before {
            position: absolute;
            top:1px;
            left: 0;
            content: '';
            display: inline-block;
            background-image: url('../img/common/arrow-black.svg');
            background-size: contain;
            width:10px;
            height: 10px;
            opacity: .5;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

//
// サブページ 各サブページへの移動
//
.card-sub {
  height: 100%;
  a {
    height: 100%;
    color: $color-text;
    display: block;
    text-decoration: none;
//    border: solid 1px transparent;
    &:hover {
//      border: solid 1px rgba($color-main,.3);
      .title,
      .text {
        color:$color-main;
      }
      .image {
        img {
          vertical-align: bottom;
          transform: scale(1.1);
          opacity: .6;
        }
      }
    }
  }
  .title {
    @include fontsize(14);
    padding: .5em 0;
    line-height: 1.2;
    color: $color-text;
    position: relative;
    padding-left: 1em;
    transition: color .2s linear;
    &:before {
      position: absolute;
      top:7px;
      left: 0;
      content: '';
      display: inline-block;
      width:14px;
      height: 14px;
      margin-right: .5em;
      background-image: url('../img/common/arrow-black.svg');
      background-size: contain;
    }
  }
  .text {
    display: none;
    @include fontsize(12);
    line-height: 1.4;
    transition: color .2s linear;
  }
  .image {
    overflow: hidden;
    background-color: $color-black;
    img {
      width: 100%;
      transition: transform .2s linear,opacity .2s linear;
      vertical-align: bottom;
    }
  }
  @media print, screen and(min-width: $screen-sm-min) {
    .title {
      @include fontsize(16);
      &:before {
        position: absolute;
        top:8px;
        left: 0;
        content: '';
        display: inline-block;
        width:15px;
        height: 15px;
        margin-right: .5em;
        background-image: url('../img/common/arrow-black.svg');
      }
    }
    .text {
      display: block;
      @include fontsize(14);
    }
  }
}

.card-division {
  @include clearfix();
  background-color: $color-white;
  padding: 1rem 2rem;
  margin-bottom: 2rem;
  .card-info {
    .title {
      @include fontsize(18);
      border-bottom: solid 1px $color-gray;
      margin-bottom: .5em;
    }
    .text {
      color:$color-text-light;
    }
    margin-bottom: 1rem;
  }
  .card-photo {
    
  }
  @media print, screen and(min-width: $screen-sm-min) {
    .card-info {
      float:left;
      width:50%;
      padding-right: 1rem;
      .title {
        @include fontsize(20);
        border-bottom: solid 1px $color-gray;
        margin-bottom: .5em;
      }
      .text {
        @include fontsize(14);
        color:$color-text-light;
      }
    }
    .card-photo {
      float:right;
      width:50%;
      padding-left: 1rem;
    }
  }
  @media print, screen and(min-width: $screen-md-min) {
    .card-info {
      .title {
        @include fontsize(22);
      }
      .text {
        @include fontsize(18);
      }
    }
  }
}

.link-card {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: rgba($color-gray,.7);
  color:$color-text;
  text-decoration: none;
  line-height: 1.2;
  .info {
    width:50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 10px 10px 40px;
  }
  .title {
    @include fontsize(18);
    display: block;
  }
  .title-eng {
    @include fontsize(9);
    color:rgba($color-text,.4);
    margin-bottom: .5rem;
  }
  .text {
    @include fontsize(14);
  }
  .image {
    width:50%;
    img {
      vertical-align: bottom;
    }
  }
  @media print, screen and(min-width: $screen-sm-min) {
    .info {
      width:30%;
    } 
    .image {
      width:70%;
    }
  }
  &:before {
    position: absolute;
    top:5%;
    left: 10px;
    content: '';
    width:10px;
    height: 90%;
    background-color: rgba($color-text,.2);
  }
  &:hover {
    background-color: rgba($color-gray,.9);
  }
}

.card-works {
  height: 100%;
  .title {
    @include fontsize(14);
    padding: .5em 0;
    line-height: 1.2;
    color: $color-text;
    position: relative;
    transition: color .2s linear;
  }
  .text {
    display: none;
    @include fontsize(12);
    line-height: 1.4;
    transition: color .2s linear;
  }
  .image {
    overflow: hidden;
    background-color: $color-black;
    img {
      width: 100%;
      transition: transform .2s linear,opacity .2s linear;
      vertical-align: bottom;
    }
  }
  @media print, screen and(min-width: $screen-sm-min) {
    .title {
      @include fontsize(16);
    }
    .text {
      display: block;
      @include fontsize(14);
    }
  }
}

