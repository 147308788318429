//
// 実績スライド
//
.slider {
  margin: 1rem auto 2rem auto;
  padding: 0;
  list-style-type: none;
  max-width: $container-md;
  li {
    position: relative;
    .slide-caption {
      position: absolute;
      bottom: 1rem;
      left: 0;
      width: 100%;
      padding: .5em;
      background-color: rgba($color-black,.6);
      color:$color-white;
      text-align: center;
    }
  }
}
.thumb {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  max-width: $container-wide;
  display: none;
  li {
    padding: 0;
    cursor: pointer;
    &:hover {
      opacity: .8;
    }
    @media print, screen and(min-width: $screen-sm-min) {
      padding: 0 10px;
    }
  }
  @media print, screen and(min-width: $screen-sm-min) {
    display: block;
  }
}

.slide-works .slider{
 max-width: 500px;
  margin-left:auto;
  margin-right:auto;
}

.list-works {
  margin: 0;
  padding: 0;
  list-style-type: none;
//  border-right: solid 1px $color-text;
  li {
    padding: 0 1em;
    @include fontsize(14);
    a {
      line-height: 1.2;
      display: inline-block;
      text-decoration: none;
      color:$color-text;
      position: relative;
      padding-left: 15px;
      &:hover {
        text-decoration: underline;
      }
      &:before {
        content: '';
        display: block;
        background-image: url(../img/common/arrow-black.svg);
        width: 15px;
        height: 15px;
        position: absolute;
        top:0;
        left: 0;
      }
    }
    span {
      display: inline-block;
      line-height: 1.2;
      padding-left: 15px;
    }
  }
  @media print, screen and(min-width: $screen-sm-min) {
    margin: .5em 0;
  }
}

.col-border-left {
  @media print, screen and(min-width: $screen-sm-min) {
    margin-bottom: 2rem;
    &:nth-child(even) {
      border-left: solid 1px $color-gray;
    }
    
  }
  
  @media print, screen and(min-width: $screen-md-min) {
    margin-bottom: 0;
    border-left: solid 1px $color-gray;
    &:first-child {
      border-left: 0;
    }
  }
}

.box-search {
  display: none;
  z-index: 100;
  position: absolute;
  top:32px;
  right: 12px;
  cursor: pointer;
}
.btn-search {
  z-index: 100;
  position: absolute;
  top:0;
  right: 12px;
  height: 32px;
  line-height: 32px;
  @include fontsize(13);
  display: inline-block;
  vertical-align: middle;
  padding: 0 30px 0 10px;
  cursor: pointer;
  &:after {
    position: absolute;
    top:0;
    right: 10px;
    display: inline-block;
    content: '';
    background-image: url('../img/common/icon-search.svg');
    background-position: right center;
    width:15px;
    height: 33px;
  }
  &:hover {
    background-color: rgba($color-white,.2);
  }
}