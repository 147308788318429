//
// - Font ----
//
@import url('https://fonts.googleapis.com/css?family=Abel');
$font-base: 16;
$font-size-base: $font-base * 1px;
$line-height-base: 1.8;
// Sans-serif
$font-family-sans-serif: 'Helvetica Neue' , Helvetica , Arial , "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif !default;
//$font-family-sans-serif: 'Helvetica Neue' , Helvetica , Arial ,"ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif !default;
$font-family-sans-serif2: 'Helvetica Neue' , Helvetica , Arial ,"ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif !default;
// serif
$font-family-serif: 'Times New Roman', 'Sawarabi Mincho', '游明朝', YuMincho, 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', 'HG明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif !default;
// Default font set
$font-family-base:$font-family-sans-serif;
//$font-family-base:$font-family-serif;

$font-family-abel: 'Abel', sans-serif;

//
// - Layout -----
//
// -- Container Size -----
// gutter-size
$gutter:24px;
// break-points
$screen-sm-min:768px;
$screen-md-min:992px;
$screen-lg-min:1230px;
// contaier-width
$container-sm:750px;
$container-md:970px;
$container-lg:1200px;
$container-wide:1500px;

//
// - Color -----
//
$site-palette: (
  primary: #147bcf,
  secondary: #ad2121,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
  color-sub: #808080,
  oshirase: #808080,
  saiyou: #003880,
);
$color-main: #1d2087;
$color-sub: #808080;
$color-accent: #ad2121;
$color-text:  #231815;
$color-text-light: #595757;
$color-black: #231815;
$color-red: #ad2121;
$color-white: #fff;
$color-gray: #E6E3E2;
$color-gray-dark: #808080;
$color-yellow: #ffff00;
$color-link:#147bcf;
